import { Divider } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const HorizontalSpacer : RFC<Props> = ({ text, size = 'xl' }) =>
    <Divider
        variant={size === 'xs' ? 'dashed' : 'solid'}
        size={size === 'none' ? 0 : size}
        label={text}
        m='xl' />
