import { RouteHref } from '@app/constant/route/RouteHref';
import { Button, ButtonProps } from '@mantine/core';
import Link from 'next/link';


export function RequestAccessButton(props : ButtonProps) : React.JSX.Element {
    return (
        <Button
            gradient={{ deg : 133, from : 'blue', to : 'cyan' }}
            aria-label='Request Early Access'
            href={RouteHref.Contact}
            variant='gradient'
            component={Link}
            radius='md'
            size='xl'
            {...props}>
            {'REQUEST EARLY ACCESS'}
        </Button>
    );
}
