import { List, Title, Text, rem, Box, Group, Stack } from '@mantine/core';
import { FeatureSectionProps } from './type';
import { App } from '@app/ui/AppContext';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const FeatureSection : RFC<FeatureSectionProps> = ({
    itemList, side, mediaComponent,
    title, subtitle, footer,
}) => {

    const { isMobile, isTablet } = useContext(App);

    const isCollapsed = isMobile || isTablet;

    const youCouldGoWithThis = isCollapsed
        ? null
        : mediaComponent;

    const youCouldGoWithThatElement = (
        <Box p='md' >
            <Title
                order={3}
                c='dimmed'
                fw={800}>
                {'Key Points'}
            </Title>
            <List
                style={{
                    marginLeft  : rem(30),
                    marginright : rem(30),
                }}>
                {itemList.map(item => (
                    <List.Item key={item.title}>
                        <Text
                            style={{
                                fontWeight : '600',
                                fontSize   : '24px',
                            }}>
                            {item.title}
                        </Text>
                        <List>
                            <List.Item>
                                <Text
                                    style={{
                                        fontWeight : '300',
                                        fontSize   : '22px',
                                    }}>
                                    {item.subtitle}
                                </Text>
                            </List.Item>
                        </List>
                    </List.Item>
                ))}
            </List>
            <Text
                style={{
                    marginLeft : rem(30),
                    marginTop  : rem(20),
                    fontSize   : '18px',
                    fontWeight : '600',
                }}
                c='dimmed'>
                {footer}
            </Text>
        </Box>
    );

    const isLeft = side === 'left';

    return (
        <Stack>
            <Box>
                <Title
                    order={1}
                    style={{
                        fontWeight    : 500,
                        fontSize      : '42px',
                        paddingBottom : rem(4),
                    }}>
                    {title}
                </Title>
                <Text
                    style={{
                        fontWeight    : '300',
                        fontSize      : '24px',
                        marginLeft    : 'rem(30)',
                        paddingBottom : rem(4),
                    }}>
                    {subtitle}
                </Text>
            </Box>
            <Group wrap='nowrap'>
                {isLeft ? youCouldGoWithThis        : youCouldGoWithThatElement}
                {isLeft ? youCouldGoWithThatElement : youCouldGoWithThis       }
            </Group>
        </Stack>
    );
};
