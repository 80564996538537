import SEOImage01 from '@app/asset/image/pub/01.png';
import SEOImage02 from '@app/asset/image/pub/02.png';
import SEOImage03 from '@app/asset/image/pub/03.png';
import SEOImage04 from '@app/asset/image/pub/04.png';
import SEOImage05 from '@app/asset/image/pub/05.png';
import SEOImage06 from '@app/asset/image/pub/06.png';
import SEOImage07 from '@app/asset/image/pub/07.png';
import SEOImage08 from '@app/asset/image/pub/08.png';
import SEOImage09 from '@app/asset/image/pub/09.png';
import SEOImage10 from '@app/asset/image/pub/10.png';
import SEOImage11 from '@app/asset/image/pub/11.png';
import SEOImage12 from '@app/asset/image/pub/12.png';
import SEOImage13 from '@app/asset/image/pub/13.png';
import SEOImage14 from '@app/asset/image/pub/14.png';
import SEOImage15 from '@app/asset/image/pub/15.png';
import SEOImage16 from '@app/asset/image/pub/16.png';
import SEOImage17 from '@app/asset/image/pub/17.png';
import SEOImage18 from '@app/asset/image/pub/18.png';
import SEOImage19 from '@app/asset/image/pub/19.png';
import SEOImage20 from '@app/asset/image/pub/20.png';
import { RouteHref } from '../constant/route/RouteHref';
import { StaticImageData } from 'next/image';
import { SEOPageData } from '@app/type/framework/SEOPageDataType';


const ImageLookup : Record<string, StaticImageData | null> = {
    [RouteHref.SEOHighlyLegalAndTechnicalAspectsOfPatents] : SEOImage01,
    [RouteHref.SEOCustomizedLessonsForBeginnersAndExperts] : SEOImage02,
    [RouteHref.SEOClearPriorArtInPatentApplications      ] : SEOImage03,
    [RouteHref.SEOSpecificationInPatentApplications      ] : SEOImage04,
    [RouteHref.SEOIndustrialApplicabilityOfPatents       ] : SEOImage05,
    [RouteHref.SEOKanbanBoardForProjectManagement        ] : SEOImage06,
    [RouteHref.SEOProvisionalPatentApplication           ] : SEOImage07,
    [RouteHref.SEODocumentUploadAndAnalysis              ] : SEOImage08,
    [RouteHref.SEOScoringAndRiskAssessment               ] : SEOImage09,
    [RouteHref.SEONonObviousnessInPatents                ] : SEOImage10,
    [RouteHref.SEOClaimsReferenceChecking                ] : SEOImage11,
    [RouteHref.SEOPatentableSubjectMatter                ] : SEOImage12,
    [RouteHref.SEOBiotechnologicalPatent                 ] : SEOImage13,
    [RouteHref.SEONoveltyInPatents                       ] : SEOImage14,
    [RouteHref.SEOUtilityInPatents                       ] : SEOImage15,
    [RouteHref.SEOSoftwarePatent                         ] : SEOImage16,
    [RouteHref.SEOClaimsBuilder                          ] : SEOImage17,
    [RouteHref.SEODesignPatent                           ] : SEOImage18,
    [RouteHref.SEOPlantPatent                            ] : SEOImage19,
    [RouteHref.SEONoteTaking                             ] : SEOImage20,
};


/* eslint-disable max-len */

export const SEOPageLookup : Record<string, SEOPageData> = {
    [RouteHref.SEOHighlyLegalAndTechnicalAspectsOfPatents] : {
        imageData : ImageLookup[RouteHref.SEOHighlyLegalAndTechnicalAspectsOfPatents],
        subtitle  : 'In-depth analysis and comprehensive insights into the intricate details of patent claims and their technical aspects.',
        footer    : 'Explore the nuances of legal and technical requirements for patents, ensuring robust and defensible intellectual property.',
        side      : 'left',
        title     : 'Highly Legal and Technical Aspects of Patents',
        itemList  : [{
            title    : 'Understanding Patent Claims',
            subtitle : 'Delve into the significance of meticulously crafted claims in patent applications, and how they define the scope and protection of your invention.',
        }, {
            title    : 'Types of Patent Claims',
            subtitle : 'Explore various types of claims, including independent, dependent, and multiple dependent claims, and understand their specific applications and benefits.',
        }, {
            title    : 'Common Pitfalls in Drafting Claims',
            subtitle : 'Identify and avoid common errors in claim drafting that can jeopardize the enforceability and breadth of your patent protection.',
        }, {
            title    : 'Legal Requirements for Claims',
            subtitle : 'Learn about the stringent legal standards that claims must meet, including clarity, conciseness, and support in the patent specification.',
        }],
    },

    [RouteHref.SEOCustomizedLessonsForBeginnersAndExperts] : {
        imageData : ImageLookup[RouteHref.SEOCustomizedLessonsForBeginnersAndExperts],
        subtitle  : 'Comprehensive and Tailored Lessons for Patent Law Enthusiasts',
        footer    : 'Dive deep into the world of patent law with lessons crafted to suit both beginners and experts. Enhance your understanding and stay updated with the latest trends and strategies.',
        side      : 'right',
        title     : 'Customized Lessons for Beginners and Experts',
        itemList  : [{
            title    : 'Introductory Patent Law',
            subtitle : 'Understand the fundamental concepts and principles of patent law, designed for those new to the field.',
        }, {
            title    : 'Advanced Patent Strategies',
            subtitle : 'Explore advanced strategies and tactics for experienced patent practitioners to maximize patent protection and value.',
        }, {
            title    : 'Patent Law Updates',
            subtitle : 'Stay informed with the latest updates, changes, and trends in patent law to ensure your knowledge remains current and comprehensive.',
        }],
    },

    [RouteHref.SEOClearPriorArtInPatentApplications] : {
        imageData : ImageLookup[RouteHref.SEOClearPriorArtInPatentApplications],
        subtitle  : 'Mastering the Identification of Clear Prior Art in Patent Applications',
        footer    : 'Gain a thorough understanding of how to identify, search for, and utilize prior art in your patent applications to strengthen and validate your intellectual property.',
        side      : 'left',
        title     : 'Clear Prior Art in Patent Applications',
        itemList  : [{
            title    : 'Defining Prior Art',
            subtitle : 'Learn what constitutes prior art and its critical role in the patent application process.',
        }, {
            title    : 'Searching for Prior Art',
            subtitle : 'Discover effective methods and tools for conducting comprehensive prior art searches to ensure novelty and non-obviousness.',
        }, {
            title    : 'Impact of Prior Art on Patentability',
            subtitle : 'Understand how prior art influences the patentability of an invention and the importance of thorough research.',
        }, {
            title    : 'Using Prior Art in Patent Drafting',
            subtitle : 'Learn how to effectively incorporate prior art references into your patent applications to strengthen your claims.',
        }, {
            title    : 'Avoiding Prior Art Pitfalls',
            subtitle : 'Identify and avoid common pitfalls related to prior art that can jeopardize the success of your patent application.',
        }],
    },

    [RouteHref.SEOSpecificationInPatentApplications] : {
        imageData : ImageLookup[RouteHref.SEOSpecificationInPatentApplications],
        subtitle  : 'Expert Guidance on Writing Specifications for Patents',
        footer    : 'Master the art of writing clear, concise, and comprehensive specifications to ensure the strength and validity of your patent applications.',
        side      : 'right',
        title     : 'Specification in Patent Applications',
        itemList  : [{
            title    : 'Introduction to Specifications',
            subtitle : 'Understand the critical role that specifications play in the success of patent applications.',
        }, {
            title    : 'Detailed Description',
            subtitle : 'Learn how to craft a detailed and accurate description of your invention to meet legal requirements and protect your intellectual property.',
        }, {
            title    : 'Best Practices for Specifications',
            subtitle : 'Explore tips and best practices for drafting specifications that clearly define your invention and its unique features.',
        }, {
            title    : 'Common Mistakes',
            subtitle : 'Identify and avoid common mistakes in writing patent specifications that could undermine the enforceability of your patent.',
        }],
    },

    [RouteHref.SEOIndustrialApplicabilityOfPatents] : {
        imageData : ImageLookup[RouteHref.SEOIndustrialApplicabilityOfPatents],
        subtitle  : 'Understanding the Criteria for Industrial Applicability in Patents',
        footer    : 'Ensure your invention meets the industrial applicability criteria to secure a strong and enforceable patent. Explore legal standards, practical examples, and solutions to common challenges.',
        side      : 'left',
        title     : 'Industrial Applicability of Patents',
        itemList  : [{
            title    : 'Defining Industrial Applicability',
            subtitle : 'Learn what industrial applicability means within the context of patent law and why it is essential for your invention.',
        }, {
            title    : 'Examples of Industrial Applicability',
            subtitle : 'Explore real-world examples that illustrate the concept of industrial applicability and how it is demonstrated in patent applications.',
        }, {
            title    : 'Legal Requirements',
            subtitle : 'Understand the legal standards and requirements for proving industrial applicability in your patent application.',
        }, {
            title    : 'Challenges and Solutions',
            subtitle : 'Identify common challenges in proving industrial applicability and discover effective solutions to overcome them.',
        }],
    },

    [RouteHref.SEOKanbanBoardForProjectManagement] : {
        imageData : ImageLookup[RouteHref.SEOKanbanBoardForProjectManagement],
        subtitle  : 'Leveraging Kanban for Effective Patent Project Management',
        footer    : 'Harness the power of Kanban boards to streamline your patent project management. Learn to set up, organize, and manage tasks efficiently while tracking progress to ensure timely project completion.',
        side      : 'left',
        title     : 'Kanban Board for Project Management',
        itemList  : [{
            title    : 'Introduction to Kanban',
            subtitle : 'Understand the basics of Kanban methodology and how it can enhance your project management workflow.',
        }, {
            title    : 'Setting Up a Kanban Board',
            subtitle : 'Learn how to set up and effectively organize a Kanban board tailored for patent projects, ensuring clear visual management.',
        }, {
            title    : 'Managing Tasks with Kanban',
            subtitle : 'Explore effective task management techniques using Kanban principles to enhance productivity and collaboration.',
        }, {
            title    : 'Tracking Progress',
            subtitle : 'Discover methods to track progress on your Kanban board, ensuring timely completion of tasks and overall project goals.',
        }],
    },

    [RouteHref.SEOProvisionalPatentApplication] : {
        imageData : ImageLookup[RouteHref.SEOProvisionalPatentApplication],
        subtitle  : 'Comprehensive Overview of Provisional Patent Applications',
        footer    : 'Gain a thorough understanding of provisional patent applications, including their benefits, the filing process, and how to avoid common pitfalls. Learn how to leverage provisional patents to protect your inventions and prepare for a non-provisional application.',
        side      : 'right',
        title     : 'Provisional Patent Application',
        itemList  : [{
            title    : 'What is a Provisional Patent?',
            subtitle : 'Learn the basics of provisional patent applications and their role in the patent process.',
        }, {
            title    : 'Benefits of Provisional Patents',
            subtitle : 'Explore the advantages of filing a provisional patent application, including cost savings and early filing dates.',
        }, {
            title    : 'How to File a Provisional Patent',
            subtitle : 'Follow a step-by-step guide to filing a provisional patent application, from preparation to submission.',
        }, {
            title    : 'Converting to a Non-Provisional Patent',
            subtitle : 'Understand the process of converting your provisional patent application to a non-provisional patent.',
        }, {
            title    : 'Common Mistakes',
            subtitle : 'Identify and avoid common mistakes in provisional patent applications to ensure a smooth and successful filing process.',
        }],
    },

    [RouteHref.SEODocumentUploadAndAnalysis] : {
        imageData : ImageLookup[RouteHref.SEODocumentUploadAndAnalysis],
        subtitle  : 'Leveraging AI for Advanced Document Upload and Analysis',
        footer    : 'Utilize cutting-edge AI technology to analyze your legal documents with precision and efficiency. Learn how to upload, manage, and extract valuable insights from your documents to streamline your workflow and enhance decision-making.',
        side      : 'left',
        title     : 'Document Upload and Analysis',
        itemList  : [{
            title    : 'Introduction to Document Analysis',
            subtitle : 'Discover how AI technology can revolutionize the analysis of legal documents, improving accuracy and saving time.',
        }, {
            title    : 'Uploading Documents',
            subtitle : 'Follow the steps to easily upload and manage your documents within the platform, ensuring a smooth and efficient process.',
        }, {
            title    : 'AI-Powered Analysis',
            subtitle : 'Explore the features and benefits of AI-powered document analysis, from identifying key information to uncovering hidden patterns.',
        }, {
            title    : 'Common Issues and Solutions',
            subtitle : 'Learn how to address and resolve common issues encountered during document analysis to maintain accuracy and reliability.',
        }, {
            title    : 'Best Practices',
            subtitle : 'Adopt best practices for using document analysis tools to maximize their potential and enhance your legal document management.',
        }],
    },

    [RouteHref.SEOScoringAndRiskAssessment] : {
        imageData : ImageLookup[RouteHref.SEOScoringAndRiskAssessment],
        subtitle  : 'Comprehensive Scoring and Risk Assessment for Patent Applications',
        footer    : 'Optimize your patent applications with detailed scoring and risk assessment insights. Learn how to effectively use scoring tools, understand the criteria, and improve your application to reduce risks and increase the chances of approval.',
        side      : 'right',
        title     : 'Scoring and Risk Assessment',
        itemList  : [{
            title    : 'Introduction to Scoring',
            subtitle : 'Learn how scoring systems work for evaluating the strength and potential of patent applications.',
        }, {
            title    : 'Risk Assessment Criteria',
            subtitle : 'Understand the key criteria used for assessing the risk associated with patent applications.',
        }, {
            title    : 'Using Scoring Tools',
            subtitle : 'Discover how to utilize scoring tools to comprehensively evaluate your patent application\'s strengths and weaknesses.',
        }, {
            title    : 'Interpreting Scores',
            subtitle : 'Gain insights into understanding and interpreting the results of your patent application scores.',
        }, {
            title    : 'Improving Your Score',
            subtitle : 'Explore actionable tips and strategies to improve your patent application score and reduce associated risks.',
        }],
    },

    [RouteHref.SEONonObviousnessInPatents] : {
        imageData : ImageLookup[RouteHref.SEONonObviousnessInPatents],
        subtitle  : 'Mastering the Concept of Non-obviousness in Patent Law',
        footer    : 'Delve into the critical aspect of non-obviousness in patent law. Understand its definition, legal standards, and see real-world examples of non-obvious inventions to enhance your patent applications.',
        side      : 'left',
        title     : 'Non-obviousness in Patents',
        itemList  : [{
            title    : 'Defining Non-obviousness',
            subtitle : 'Learn what non-obviousness means in the context of patent law and why it is essential for securing a patent.',
        }, {
            title    : 'Legal Standards',
            subtitle : 'Understand the legal standards and requirements for proving non-obviousness in your patent applications.',
        }, {
            title    : 'Examples of Non-obviousness',
            subtitle : 'Explore real-world examples that illustrate the concept of non-obviousness and how it applies to various inventions.',
        }],
    },

    [RouteHref.SEOClaimsReferenceChecking] : {
        imageData : ImageLookup[RouteHref.SEOClaimsReferenceChecking],
        subtitle  : 'Comprehensive Guide to Reference Checking for Patent Claims',
        footer    : 'Ensure the integrity and strength of your patent claims with meticulous reference checking. Learn the importance, steps, tools, and solutions to common issues to enhance the reliability of your patent applications.',
        side      : 'right',
        title     : 'Claims Reference Checking',
        itemList  : [{
            title    : 'Importance of Reference Checking',
            subtitle : 'Understand why thorough reference checking is crucial for the validity and enforceability of patent claims.',
        }, {
            title    : 'How to Check References',
            subtitle : 'Learn the step-by-step process to perform comprehensive reference checks for your patent claims.',
        }, {
            title    : 'Tools for Reference Checking',
            subtitle : 'Discover tools and resources that facilitate efficient and accurate reference checking.',
        }, {
            title    : 'Common Issues',
            subtitle : 'Identify common issues encountered during reference checking and how to effectively address them.',
        }],
    },
    [RouteHref.SEOPatentableSubjectMatter] : {
        imageData : ImageLookup[RouteHref.SEOPatentableSubjectMatter],
        subtitle  : 'Understanding What Constitutes Patentable Subject Matter',
        footer    : 'Gain a clear understanding of what qualifies as patentable subject matter. Learn about the legal requirements and see examples to ensure your inventions meet the criteria for patentability.',
        side      : 'left',
        title     : 'Patentable Subject Matter',
        itemList  : [{
            title    : 'Defining Patentable Subject Matter',
            subtitle : 'Learn what constitutes patentable subject matter and why it is essential for securing a patent.',
        }, {
            title    : 'Examples of Patentable Subject Matter',
            subtitle : 'Explore real-world examples of inventions that qualify as patentable subject matter.',
        }, {
            title    : 'Legal Requirements',
            subtitle : 'Understand the legal requirements that must be met for an invention to be considered patentable.',
        }],
    },

    [RouteHref.SEOBiotechnologicalPatent] : {
        imageData : ImageLookup[RouteHref.SEOBiotechnologicalPatent],
        subtitle  : 'In-depth Insights on Biotechnological Patents',
        footer    : 'Explore the intricacies of biotechnological patents, including legal requirements, real-world examples, and common challenges. Gain a comprehensive understanding to navigate the complexities of biotechnology patents successfully.',
        side      : 'right',
        title     : 'Biotechnological Patent',
        itemList  : [{
            title    : 'Introduction to Biotechnological Patents',
            subtitle : 'Learn the basics of biotechnological patents and their importance in protecting innovations in biotechnology.',
        }, {
            title    : 'Examples of Biotechnological Patents',
            subtitle : 'Discover examples and case studies that illustrate successful biotechnological patents.',
        }, {
            title    : 'Challenges in Biotechnology',
            subtitle : 'Identify common challenges faced in securing biotechnology patents and explore strategies to overcome them.',
        }, {
            title    : 'Legal Requirements',
            subtitle : 'Understand the legal standards and requirements specific to biotechnological patents.',
        }],
    },
    [RouteHref.SEONoveltyInPatents] : {
        imageData : ImageLookup[RouteHref.SEONoveltyInPatents],
        subtitle  : 'Comprehensive Guide to Assessing Novelty in Patent Applications',
        footer    : 'Learn how to effectively assess and prove the novelty of your inventions. Understand the legal standards, see examples, and avoid common mistakes to enhance the success of your patent applications.',
        side      : 'left',
        title     : 'Novelty in Patents',
        itemList  : [{
            title    : 'Defining Novelty',
            subtitle : 'Understand what novelty means in patent law and why it is crucial for obtaining a patent.',
        }, {
            title    : 'How to Prove Novelty',
            subtitle : 'Learn the steps and methods to effectively prove the novelty of your invention in your patent application.',
        }, {
            title    : 'Examples of Novel Inventions',
            subtitle : 'Explore examples of inventions that successfully demonstrate novelty and gain insights from their applications.',
        }, {
            title    : 'Common Mistakes',
            subtitle : 'Identify common mistakes in proving novelty and learn how to avoid them to strengthen your patent application.',
        }],
    },

    [RouteHref.SEOUtilityInPatents] : {
        imageData : ImageLookup[RouteHref.SEOUtilityInPatents],
        subtitle  : 'Comprehensive Guide to Utility Requirements for Patents',
        footer    : 'Understand the importance of utility in patent applications. Learn the legal standards, see examples of useful inventions, and overcome common challenges with best practices for demonstrating the utility of your invention.',
        side      : 'right',
        title     : 'Utility in Patents',
        itemList  : [{
            title    : 'Understanding Utility',
            subtitle : 'Learn what utility means in patent law and why it is essential for securing a patent.',
        }, {
            title    : 'Examples of Useful Inventions',
            subtitle : 'Explore examples of inventions that successfully meet the utility requirement.',
        }, {
            title    : 'Legal Standards',
            subtitle : 'Understand the legal standards and requirements for proving the utility of your invention.',
        }, {
            title    : 'Common Challenges',
            subtitle : 'Identify common challenges in proving utility and discover strategies to overcome them.',
        }, {
            title    : 'Best Practices',
            subtitle : 'Learn best practices for effectively demonstrating the utility of your invention in your patent application.',
        }],
    },
    [RouteHref.SEOSoftwarePatent] : {
        imageData : ImageLookup[RouteHref.SEOSoftwarePatent],
        subtitle  : 'In-depth Understanding of Software Patents',
        footer    : 'Navigate the complexities of software patents with ease. Learn the basics, legal requirements, see successful examples, and overcome challenges with best practices for drafting and filing software patents.',
        side      : 'left',
        title     : 'Software Patent',
        itemList  : [{
            title    : 'Basics of Software Patents',
            subtitle : 'Get an introduction to software patents and understand their importance in protecting software innovations.',
        }, {
            title    : 'Legal Requirements for Software Patents',
            subtitle : 'Learn about the legal standards and requirements for obtaining software patents.',
        }, {
            title    : 'Examples of Software Patents',
            subtitle : 'Explore case studies and examples of successful software patents to gain insights.',
        }, {
            title    : 'Challenges in Software Patents',
            subtitle : 'Identify common challenges in obtaining software patents and discover how to address them.',
        }, {
            title    : 'Best Practices',
            subtitle : 'Learn best practices for drafting, filing, and securing software patents effectively.',
        }],
    },

    [RouteHref.SEOClaimsBuilder] : {
        imageData : ImageLookup[RouteHref.SEOClaimsBuilder],
        subtitle  : 'Step-by-Step Guide to Building Strong Patent Claims',
        footer    : 'Master the art of claims building with comprehensive guidance on tools, resources, and best practices for drafting robust patent claims that stand up to scrutiny.',
        side      : 'right',
        title     : 'Claims Builder',
        itemList  : [{
            title    : 'Introduction to Claims Building',
            subtitle : 'Learn the basics of building strong, effective patent claims that protect your invention.',
        }, {
            title    : 'Tools for Claims Building',
            subtitle : 'Discover tools and resources that can assist in drafting precise and defensible patent claims.',
        }, {
            title    : 'Best Practices',
            subtitle : 'Explore best practices for writing patent claims to ensure clarity and enforceability.',
        }],
    },

    [RouteHref.SEODesignPatent] : {
        imageData : ImageLookup[RouteHref.SEODesignPatent],
        subtitle  : 'Comprehensive Information on Design Patents',
        footer    : 'Gain insights into design patents, including their importance, legal standards, and real-world examples. Understand how to effectively secure and leverage design patents for your innovations.',
        side      : 'left',
        title     : 'Design Patent',
        itemList  : [{
            title    : 'Understanding Design Patents',
            subtitle : 'Learn what design patents are, their significance, and how they differ from other types of patents.',
        }, {
            title    : 'Legal Standards for Design Patents',
            subtitle : 'Understand the legal requirements and standards for obtaining design patents.',
        }, {
            title    : 'Examples of Design Patents',
            subtitle : 'Explore case studies and examples of design patents to see how they are applied in various industries.',
        }],
    },

    [RouteHref.SEOPlantPatent] : {
        imageData : ImageLookup[RouteHref.SEOPlantPatent],
        subtitle  : 'Comprehensive Guide to Plant Patents',
        footer    : 'Understand the significance of plant patents, including their legal requirements, and explore real-world examples and common challenges. Equip yourself with the knowledge to successfully secure plant patents for your botanical innovations.',
        side      : 'right',
        title     : 'Plant Patent',
        itemList  : [{
            title    : 'Introduction to Plant Patents',
            subtitle : 'Learn the basics of plant patents and why they are essential for protecting botanical innovations.',
        }, {
            title    : 'Legal Requirements for Plant Patents',
            subtitle : 'Understand the legal standards and processes for obtaining plant patents.',
        }, {
            title    : 'Examples of Plant Patents',
            subtitle : 'Explore case studies and examples of successful plant patents.',
        }, {
            title    : 'Challenges in Plant Patents',
            subtitle : 'Discover the common challenges faced in obtaining plant patents and how to overcome them.',
        }],
    },

    [RouteHref.SEONoteTaking] : {
        imageData : ImageLookup[RouteHref.SEONoteTaking],
        subtitle  : 'Strategies for Effective Note Taking',
        footer    : 'Master the art of note taking with strategies and tools that enhance your patent application process. Learn best practices, avoid common mistakes, and keep your notes organized and efficient.',
        side      : 'left',
        title     : 'Note Taking',
        itemList  : [{
            title    : 'Importance of Note Taking',
            subtitle : 'Understand why meticulous note taking is crucial for the success of your patent applications.',
        }, {
            title    : 'Tools for Note Taking',
            subtitle : 'Discover a range of tools and techniques for effective and efficient note taking.',
        }, {
            title    : 'Best Practices',
            subtitle : 'Learn best practices for organizing and managing your notes to enhance clarity and accessibility.',
        }, {
            title    : 'Common Mistakes',
            subtitle : 'Identify and avoid common mistakes in note taking to ensure accuracy and reliability.',
        }],
    },
};

/* eslint-enable max-len */
